<template>
  <div class="container">
    <transition>
      <div>
        <BaseHeader :title="$t('views.seller.store.showcase')" class="header mb-5" data-anima="top">
          <div class="mt-3 mt-md-0 d-flex align-items-center d-md-block">
            <div v-show="isMobile">
              <b-tooltip
                target="filtro-1"
                :title="$t('views.seller.store.apply_filter')"
                placement="topright"
              />
              <b-button
                id="filtro-1"
                class="btn-table"
                :title="$t('views.seller.store.filters')"
                :disabled="loading"
                @click="showFilters = !showFilters"
                ><img src="@/assets/img/icons/filtro.svg"
              /></b-button>
            </div>
            <div class="inputSearch">
              <img src="@/assets/img/icons/search.svg" class="Pointer search" />
              <input
                type="text"
                :placeholder="$t('views.seller.store.search_for_product')"
                v-on:input="debounce"
                v-model="data.search"
                class="input-busca"
              />
            </div>
          </div>
        </BaseHeader>

        <section class="store-grid">
          <div class="filtros" v-show="!loading && !isMobile" data-anima="left">
            <!-- categorias checkbox -->
            <div class="checks">
              <h3>{{ $t('views.seller.store.categories') }}</h3>
              <div
                v-for="categoria in categoriasFormat"
                :key="categoria.id"
                class="categoria"
              >
                <b-form-checkbox
                  :value="categoria"
                  class="checkbox-categoria"
                  :id="`checkbox-group-` + categoria.text"
                  :name="`flavour-` + categoria.text"
                  v-model="categoria_check"
                  @change="changeCategoria(categoria.id)"
                >
                  <span> {{ categoria.text }}</span>
                </b-form-checkbox>
              </div>
              <a href="#" class="ver-mais" @click.prevent="maisCategorias">{{
                categorias.length > categoriasFormat.length
                  ? $t('views.seller.store.see_more')
                  : $t('views.seller.store.see_less')
              }}</a>
            </div>

            <!-- valor de comissão -->
            <div class="mt-3 comissao">
              <h3>{{ $t('views.seller.store.commission_amount') }}</h3>
              <vue-slider
                :min="comissao_valor[0]"
                :max="comissao_valor[1]"
                :interval="10"
                v-model="data.comission"
                @change="debounceFilter"
              >
              </vue-slider>
              <div class="mt-2 input-comissao">
                <div>
                  <input
                    type="number"
                    v-model="data.comission[0]"
                    @keyup="debounceFilter"
                  />
                </div>
                <div>
                  <input
                    type="number"
                    v-model="data.comission[1]"
                    @keyup="debounceFilter"
                  />
                </div>
              </div>
            </div>

            <!-- Avaliação -->
            <div class="mt-3 avaliacao">
              <h3>{{ $t('views.seller.store.evaluation') }}</h3>
              <vue-slider
                v-model="data.avaliation"
                :min="0"
                :max="5"
                :interval="1"
                @change="debounceFilter"
              >
              </vue-slider>
            </div>
          </div>

          <div
            class="filtros"
            v-show="!loading && isMobile && showFilters"
            data-anima="top"
          >
            <!-- categorias checkbox -->
            <div class="checks">
              <h3>{{ $t('views.seller.store.categories') }}</h3>
              <div
                v-for="categoria in categoriasFormat"
                :key="categoria.id"
                class="categoria"
              >
                <b-form-checkbox
                  :value="categoria"
                  class="checkbox-categoria"
                  :id="`checkbox-group-` + categoria.text"
                  :name="`flavour-` + categoria.text"
                  v-model="categoria_check"
                  @change="changeCategoria(categoria.id)"
                >
                  <span> {{ categoria.text }}</span>
                </b-form-checkbox>
              </div>
              <a href="#" class="ver-mais" @click.prevent="maisCategorias">{{
                categorias.length > categoriasFormat.length
                  ? $t('views.seller.store.see_more')
                  : $t('views.seller.store.see_less')
              }}</a>
            </div>

            <!-- valor de comissão -->
            <div class="mt-3 comissao">
              <h3>{{ $t('views.seller.store.commission_amount') }}</h3>
              <vue-slider
                :min="comissao_valor[0]"
                :max="comissao_valor[1]"
                :interval="10"
                v-model="data.comission"
                @change="debounceFilter"
              >
              </vue-slider>
              <div class="mt-2 input-comissao">
                <div>
                  <input
                    type="number"
                    v-model="data.comission[0]"
                    @keyup="debounceFilter"
                  />
                </div>
                <div>
                  <input
                    type="number"
                    v-model="data.comission[1]"
                    @keyup="debounceFilter"
                  />
                </div>
              </div>
            </div>

            <!-- Avaliação -->
            <div class="mt-3 avaliacao">
              <h3>{{ $t('views.seller.store.evaluation') }}</h3>
              <vue-slider
                v-model="data.avaliation"
                :min="0"
                :max="5"
                :interval="1"
                @change="debounceFilter"
              >
              </vue-slider>
            </div>
          </div>

          <div
            v-if="loading"
            class="py-4 d-flex justify-content-center align-items-center"
          >
            <b-spinner variant="dark" label="Spinning"></b-spinner>
          </div>
          <div class="tabs-vitrine">
            <b-tabs
              nav-class="TabVitrine no-border"
              active-nav-item-class="TabVitrine--active"
              :no-nav-style="true"
            >
              <b-tab title="Mais vendidos" active @click="tabActive('sell')">
                <div class="container-cards" v-if="!loading">
                  <div
                    v-for="product in products"
                    :key="product.id"
                    class="card-item"
                    @click="openRecrutamento(product)"
                  >
                    <CardProduct
                      :product="product"
                      :taxa_transacao="taxa_transacao"
                      :taxa_afiliado="taxa_afiliado"
                      :isMobile="isMobile"
                      data-anima="bottom"
                    />
                  </div>
                </div>
              </b-tab>
              <b-tab title="Mais recentes" @click="tabActive('recent')">
                <div class="container-cards" v-if="!loading">
                  <div
                    v-for="product in products"
                    :key="product.id"
                    class="card-item"
                    @click="openRecrutamento(product)"
                  >
                    <CardProduct :product="product" data-anima="bottom" />
                  </div></div
              ></b-tab>
              <div
                v-if="loading && !products.length"
                class="py-4 d-flex justify-content-center align-items-center"
              >
                <b-spinner variant="dark" label="Spinning"></b-spinner>
              </div>

              <b-row
                v-if="!loading && !products.length"
                class="justify-content-center"
              >
                <p class="nao-encontrado">{{ $t('views.seller.store.no_product_found') }}</p>
              </b-row>
              <b-row v-if="!loading">
                <b-col>
                  <Paginate
                    v-if="products.length > 0"
                    :totalPages="pagination.totalPages"
                    :activePage="pagination.currentPage"
                    @to-page="toPage"
                    @per-page="perPage"
                  />
                </b-col>
              </b-row>
            </b-tabs>
          </div>
        </section>
      </div>
    </transition>
  </div>
</template>
<script>
import _ from "lodash";

import ProductService from "@/services/resources/ProductService";
const serviceProduct = ProductService.build();

import CategoryService from "@/services/resources/CategoryService";
const serviceCategory = CategoryService.build();

import GlobalSettings from "@/services/resources/GlobalSettings";
const serviceGlobalSettings = GlobalSettings.build();

import AffiliateService from "@/services/resources/AffiliateService";
const serviceAffiliate = AffiliateService.build();

import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

import CardProduct from "@/components/Store/CardProduct";
import Paginate from "@/components/shared/Paginate";

export default {
  name: "Vitrine",
  components: {
    VueSlider,
    CardProduct,
    Paginate,
  },
  data() {
    return {
      showFilters: false,
      client: {
        width: 0,
      },
      taxa_transacao: 0,
      taxa_afiliado: 0,
      loading: false,
      products: [],
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 12,
      },
      categoria_check: [],
      comissao_valor: [0, 1600],
      data: {
        comission: [0, 1600],
        avaliation: 0,
        category_id: [],
        search: "",
      },
      categorias: [],
      categoriasFormat: [],
    };
  },
  created() {
    if (this.$store.getters.setPanel) {
      this.$router.push("/dashboard");
    }
  },
  computed: {
    isMobile() {
      // return this.client.width <= 768;
      return this.client.width <= 768;
    },
  },
  methods: {
    handleResize() {
      // padrão
      this.client.width = window.innerWidth;
    },
    tabActive(orderBy) {
      this.pagination.currentPage = 1;
      this.data = {
        comission: [0, 1600],
        avaliation: 0,
        category_id: [],
        search: "",
      };
      this.fetchProducts(orderBy);
      this.categoria_check = [];
    },
    debounceFilter: _.debounce(function() {
      this.fetchProducts();
    }, 1200),

    changeCategoria(id) {
      const contem = [...this.data.category_id].findIndex((item) => item == id);
      if (contem > -1) {
        this.data.category_id.splice(contem, 1);
      } else {
        this.data.category_id.push(id);
      }

      this.pagination.currentPage = 1;

      this.fetchProducts();
    },
    openRecrutamento(product) {
      let data = {
        product_id: product.id,
        url: "invite",
      };

      serviceAffiliate
        .search(data)
        .then((response) => {
          this.$router.push(
            `/recrutamento/${response.hash}/?p_id=${product.id}&store=true`
          );
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    // openRecrutamento2(product) {
    //   let data = {
    //     product_id: product.id,
    //   };

    //   serviceAffiliate
    //     .create(data)
    //     .then((response) => {
    //       this.$router.push(
    //         `/recrutamento/${response.hash}/?p_id=${product.id}&store=true`
    //       );
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     })
    //     .finally(() => {
    //       this.loading = false;
    //     });
    // },
    debounce: _.debounce(function() {
      this.pagination.currentPage = 1;
      this.fetchProducts();
    }, 500),

    maisCategorias() {
      if (this.categorias.length > this.categoriasFormat.length) {
        this.categoriasFormat = [];
        this.categorias.forEach((element) => {
          this.categoriasFormat.push(element);
        });
      } else {
        this.categoriasFormat = [];
        this.categorias.forEach((element, index) => {
          if (index < 4) {
            this.categoriasFormat.push(element);
          }
        });
      }
    },
    toPage(page) {
      this.pagination.currentPage = page;
      this.fetchProducts();
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;

      this.fetchProducts();
    },

    // buscando produtos
    fetchProducts(orderBy = "sell") {
      if (this.isMobile) {
        this.showFilters = false;
      }
      this.loading = true;
      this.products = [];
      this.pagination.totalPages = 1;

      var data = {
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
        comission_start: +this.data.comission[0],
        comission_end: +this.data.comission[1],
        type: "public",
        orderBy,
      };
      this.pagination.totalPages = 1;

      if (
        this.data.search != null &&
        typeof this.data.search == "string" &&
        this.data.search.length > 0
      ) {
        data.search = this.data.search;
      }

      if (this.data.category_id.length) {
        data.category_id = this.data.category_id;
      }

      if (this.data.avaliation) {
        data.avaliation = +this.data.avaliation;
      }

      serviceProduct
        .search(data)
        .then((response) => {
          this.products = response.data;
          this.pagination.totalPages = response.last_page;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    fetchCategories() {
      serviceCategory
        .search()
        .then((response) => {
          response.forEach((element, index) => {
            this.categorias.push({ id: element.id, text: element.name });

            if (index < 4) {
              this.categoriasFormat.push({
                id: element.id,
                text: element.name,
              });
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchGlobalSettings() {
      let data = {
        keys: "SELLER_FEE_TRANSACTION,AFFILIATE_FEE",
      };
      serviceGlobalSettings.search(data).then((response) => {
        response.forEach((item) => {
          if (item.key == "AFFILIATE_FEE") {
            this.taxa_afiliado = +item.value;
          }
          if (item.key == "SELLER_FEE_TRANSACTION") {
            this.taxa_transacao = +item.value;
          }
        });
      });
    },
  },
  mounted() {
    this.fetchGlobalSettings();
    this.fetchCategories();
    this.fetchProducts();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
.inputSearch {
  position: relative;
}
.input-busca {
  width: 375px;
  padding-left: 45px !important;
}
.search {
  position: absolute;
  left: 22px;
  top: 20px;
  width: 14.7px;
  height: 14.7px;
  z-index: 999;
}
.store-grid {
  display: grid;
  grid-template-columns: 180px 1fr;
  gap: 40px;
}
.filtros {
  padding-right: 10px;
}
.filtros h3 {
  font-weight: 600;
  color: #2a63ab;
  font-size: 14px;
  margin-bottom: 15px;
}
/* categorias */

.categoria + .categoria {
  margin-top: 10px;
}
.categoria span {
  color: #81858e;
  font-size: 13px;
  margin: 0;
}
.ver-mais {
  display: inline-block;
  color: #2a63ab;
  font-size: 13px;
  margin-top: 10px;
}
/* comissao */
.input-comissao {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.input-comissao input {
  /* background: red; */
  max-width: 70px;
  height: 30px !important;
  padding: 5px 7px !important;
  padding-left: 25px !important;
  font-size: 13px !important;
}
.input-comissao div {
  position: relative;
}
.input-comissao div::before {
  content: "R$";
  position: absolute;
  top: 5px;
  left: 0.5em;
  font-size: 12px;
}
.input-comissao input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.input-comissao input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

/* container */
.container-cards {
  padding: 20px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px 15px;
}
.container-cards .card-item {
  flex: 1;
}
.btn-table {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: rgba(237, 237, 240, 0.5);
  outline: none;
}
.btn-table:active {
  background: rgba(237, 237, 240, 0.5);
  border: none;
}
.btn-table img {
  filter: invert(50%);
}
.btn-table:first-child {
  background: rgba(0, 33, 99, 0.1);
}
.btn-table:first-child:active {
  background: rgba(0, 33, 99, 0.1);
  border: none;
}
.btn-table:first-child img {
  filter: invert(0);
}

@media screen and (max-width: 768px) {
  .inputSearch {
    width: 100%;
    margin: 0 !important;
    margin-left: 20px !important;
  }
  .input-busca {
    width: auto;
  }
  .store-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }
  .container-cards {
    grid-template-columns: 1fr 1fr;
    padding: 0;
    gap: 10px;
  }
}
</style>
